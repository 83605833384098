@use "../base/vars" as *;

.works{
    --swiper-theme-color: #fff;
    
    position: relative;
    z-index: 2;
    
    .works_contents{
        margin-top: 120px;
        
        @include media_query(){
            margin-top: 80px;
        }
        
        .works_contents_inner{
            display: flex;
            max-width: 1080px;
            margin: 0 auto;
            padding: 0 20px;
            gap: 20px;
            
            @include media_query(){
                flex-direction: column;
            }
        }
    }
}

.page_bg{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        @include media_query(){
            
        }
    }
}

.page_head{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: fixed;
    
    .page_title{
        background-color: #fff;
        padding: 200px 20px 0;
        
        @include media_query(){
            padding: 100px 25px 0;
        }
        
        h2{
            max-width: 1040px;
            margin: 0 auto 80px;
            font-size: 78px;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            
            @include media_query(){
                font-size: 48px;
                margin: 0 auto 50px;
            }
            
            span{
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: bold;
                display: block;
                font-size: 18px;
                letter-spacing: .04em;
                @include media_query(){
                    font-size: 15px;
                }
            }
        }
    }
    
    .page_introduction{
        // margin-top: 470px;
        background-color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 1.8;
        // padding: 120px 20px;
        
        @include media_query(){
            // margin-top: 210px;
            // padding: 80px 25px 75px;
        }
        
        br{
            &.pc_br{
                @include media_query(){
                    display: none;
                }
            }
            
            &.sp_br{
                display: none;
                
                @include media_query(){
                    display: block;
                }
            }
        }
    }
}





.works_contents_main{
    width: 100%;
    max-width: 750px;
    margin-left: auto;
}

.notpost{
    text-align: center;
}

.works_list_head{
    display: grid;
    grid-template-columns: 1fr 90px 120px;
    gap: 20px;
    
    @include media_query(){
        display: none;
    }
    
    p{
        font-weight: bold;
        border-bottom: solid 2px #000;
        text-align: center;
        padding-bottom: 15px;
    }
}

.works_list{
    .works_list_ul{
        @include media_query(){
            border-top: solid 1px #ccc;
        }
        
        li{
            display: grid;
            grid-template-columns: 1fr 90px 120px;
            gap: 20px;
            border-bottom: solid 1px #ccc;
            padding: 25px 0;
            
            @include media_query(){
                display: block;
                padding: 15px 0;
                // grid-template-columns: 1fr 1fr;
            }
            
            .list_title{
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0 20px;
                font-size: 16px;
                
                @include media_query(){
                    padding: 0;
                }
                
                a[data-target]{
                    text-decoration: underline;
                    cursor: pointer;
                }
                
                .cat{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-left: auto;
                    
                    span,a{
                        display: inline-block;
                        font-size: 13px;
                        padding: 5px 12px;
                        border-radius: 30px;
                        background-color: #ebf2f2;
                        white-space: nowrap;
                    }
                }
                
                
            }
            
            .sp_none{
                @include media_query(){
                    display: none;
                }
            }
            
            p{
                text-align: center;
                font-size: 16px;
                
                @include media_query(){
                    display: inline-block;
                    font-size: 14px;
                    
                    & + p{
                        margin-left: 1em;
                    }
                }
                
                i{
                    display: none;
                    
                    @include media_query(){
                        display: inline;
                    }
                }
            }
        }
    }
}





dialog{
    width: 100%;
    height: 100%;
    max-width: 90vw;
    max-height: 90vh;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    overflow: hidden;
    
    @include media_query(){
        max-width: 100%;
        max-height: 100vh;
    }
    
    &[open]{
        animation: slide-up .4s ease-out;
    }
    
    .dialog_inner{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // position: relative;
        // padding: 30px;
        
        .btn_close{
            position: absolute;
            right: 0px;
            top: 0;
            z-index: 9;
            width: 48px;
            height: 48px;
            cursor: pointer;
            
            @include media_query(){
                right: 20px;
                top: 15px;
            }
            
            &::before,
            &::after{
                position: absolute;
                top: 50%;
                left: 0%;
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: #fff;
            }
            
            &::before{
                rotate: 45deg;
            }
            
            &::after{
                rotate: -45deg;
            }
        }
        
        img{
            // width: auto;
            // height: auto;
            // max-width: 100%;
            // max-height: 100%;
            // vertical-align: bottom;
        }
    }
    
    &::backdrop{
        background-color: rgba(#000, .9);
        
        @include media_query(){
            width: 101%;
        }
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translate(0, 30px);
    }
    
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.swiper{
    width: 100%;
    height: 100vh;
    
    .swiper-wrapper{
        width: 100%;
        height: 100vh;
    }

    .swiper-slide{
        display: grid !important;
        place-items: center;
        width: 100% !important;
        height: 100% !important;
        // background-color: #f00;
        
        img{
            width: auto;
            height: auto;
            max-width: 900px;
            max-height: 900px;
            object-fit: contain;
            
            @include media_query(){
                width: 375px;
                height: 375px;
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev{
        // &::before,
        // &::after{
        //     content: "";
        //     width: 22px;
        //     height: 44px;
        //     background-image: url(../svg/dialogarrow.svg);
        //     background-repeat: no-repeat;
        //     background-position: center center;
        //     background-size: contain;
        // }
        
        @include media_query(){
            display: none !important;
        }
        
        &.swiper-button-disabled{
            display: none;
        }
    }
    
    .swiper-button-prev{
        &::after{
            content: "";
            width: 22px;
            height: 44px;
            background-image: url(../svg/dialogarrow.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
    
    .swiper-button-next{
        &::after{
            content: "";
            width: 22px;
            height: 44px;
            background-image: url(../svg/dialogarrow.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            rotate: 180deg;
        }
    }
}


