@use "../base/vars" as *;

.recruit{
    position: relative;
    z-index: 2;
    
    .recruit_inner{
        .page_head{
            .page_introduction{
                // padding: 80px 20px 0;
                
                &.entry_introduction{
                    // padding: 40px 0 0 0;
                    // margin: 0;
                    
                    @include media_query(){
                        text-align: left;
                        // padding: 0 25px;
                    }
                }
            }
        }
    }
    
    .recruit_contents{
        margin-top: 120px;
        
        @include media_query(){
            margin-top: 80px;
        }
        
        .recruit_contents_inner{
            
        }
    }
}

.localnav{
    border-top: solid 1px rgba(#000, .1);
    padding: 55px 0 65px 0;
    background-color: #fff;
    
    @include media_query(){
        padding: 30px 25px;
    }
    
    ul{
        max-width: 1040px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 50px;
        
        @include media_query(){
            display: grid;
            grid-template-columns: 130px 150px;
            gap: 15px;
        }
        
        li{
            a{
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
            }
        }
    }
}





.recruit_section{
    max-width: 1040px;
    margin: 0 auto;
    
    &.intaview{
        max-width: 100%;
        background-color: #f2f6f6;
        padding: 120px 0 160px 0;
        margin-top: 160px;
        
        @include media_query(){
            margin-top: 80px;
            padding: 60px 0 80px 0;
        }
        
        .recruit_section_inner{
            max-width: 1040px;
            margin: 0 auto;
        }
    }
    
    &.faq{
        padding: 120px 0 0 0;
        
        @include media_query(){
            padding: 75px 0 80px 0;
        }
    }
}

.recruit_section_inner{
    display: grid;
    grid-template-columns: 1fr 650px;
    gap: 20px;
    
    @include media_query(1040){
        grid-template-columns: 1fr;
        gap: 60px;
    }
    
    .section_title{
        @include media_query(1040){
            padding: 0 25px;
        }
        
        h3{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 38px;
            letter-spacing: .01em;
            
            @include media_query(){
                font-size: 36px;
            }
            
            span{
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: 500;
                display: block;
                font-size: 12px;
                letter-spacing: .04em;
                // font-weight: normal;
            }
        }
    }
    
    .section_main{
    }
}

.requirements{
    display: grid;
    grid-template-columns: 145px 1fr;
    gap: 0 25px;
    
    @include media_query(1040){
        grid-template-columns: 75px 1fr;
        padding: 0 25px;
    }
    
    & + .requirements{
        margin-top: 120px;
        
        @include media_query(){
            margin-top: 60px;
        }
    }
    
    dt,
    dd{
        border-top: solid 1px #ccc;
        padding: 30px 0;
        font-size: 15px;
        letter-spacing: .02em;
        
        @include media_query(){
            font-size: 14px;
        }
    }
    
    dt{
        font-weight: bold;
        
        &:last-of-type{
            border-bottom: solid 1px #ccc;
        }
    }
    
    dd{
        line-height: 1.8;
        
        &:last-of-type{
            border-bottom: solid 1px #ccc;
        }
        
        strong{
            font-weight: bold;
        }
    }
}

.btn_entry{
    margin-top: 120px;
    
    @include media_query(){
        margin-top: 60px;
    }
    
    a{
        display: grid;
        place-items: center;
        width: 320px;
        height: 72px;
        margin: 0 auto;
        background-color: #000;
        border-radius: 50px;
        color: #fff;
        font-size: 16px;
        letter-spacing: .06em;
        
        background-image: url(../svg/icon_arrow.svg);
        background-repeat: no-repeat;
        background-position: right 30px center;
        background-size: 15px 3px;
        
        transition: opacity .25s;
        
        @include media_query(){
            background-size: 18px 10px;
        }
        
        &:hover{
            opacity: .7;
        }
    }
}






.intaview_article{
    
    & + .intaview_article{
        margin-top: 160px;
        
        @include media_query(){
            margin-top: 115px;
        }
    }
    
    &:nth-child(even){
        .icon_intaview{
            @include media_query(1040){
                left: 25px;
                right: auto;
                top: -55px;
            }
        }
    }
    
    .intaview_head{
        position: relative;
        
        .icon_intaview{
            position: absolute;
            left: -80px;
            top: 40px;
            width: 100%;
            max-width: 123px;
            height: auto;
            
            @include media_query(1040){
                left: auto;
                right: 25px;
                top: -55px;
                max-width: 98px;
            }
        }
        
        .intaview_img{
            width: 100%;
            height: auto;
            
            img{
                width: 100%;
                height: auto;
                
            }
        }
        
        .name{
            display: flex;
            align-items: flex-end;
            padding-bottom: 40px;
            border-bottom: solid 1px #ccc;
            
            @include media_query(1040){
                padding: 0 25px 30px;
            }
            
            h3{
                font-size: 21px;
                letter-spacing: .15em;
                
                span{
                    display: block;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    letter-spacing: .01em;
                    margin-bottom: 15px;
                }
            }
            
            .career{
                margin-left: auto;
                font-size: 15px;
                letter-spacing: .01em;
            }
        }
    }
}

.intaview_main{
    margin-top: 60px;
    
    @include media_query(1040){
        padding: 0 25px;
    }
    
    h4{
        font-size: 26px;
        font-weight: bold;
        letter-spacing: .01em;
        line-height: 1.6;
    }
}

.intaview_main_section{
    margin-top: 70px;
    
    & + .intaview_main_section{
        margin-top: 35px;
    }
    
    h5{
        display: flex;
        align-items: center;
        gap: .5em;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 30px;
        
        &::before{
            content: "";
            width: 45px;
            height: 1px;
            background-color: #000;
        }
    }
    
    p{
        font-size: 16px;
        letter-spacing: .01em;
        line-height: 1.8;
    }
    
    img{
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.message{
    margin-top: 65px;
    padding: 25px 0;
    background-color: #fff;
    display: grid;
    grid-template-columns: 288px 1fr;
    
    @include media_query(){
        grid-template-columns: 1fr;
        gap: 25px;
        padding: 45px 25px 25px;
    }
    
    .message_img{
        @include media_query(){
            order: 1;
        }
        
        img{
            width: 100%;
            height: auto;
        }
    }
    
    .message_text{
        position: relative;
        padding: 35px 30px 0;
        
        @include media_query(){
            padding: 0;
        }
        
        img{
            position: absolute;
            top: -40px;
            right: 30px;
            width: 212px;
            height: auto;
            margin-top: -12px;
            
            @include media_query(){
                top: -50px;
                right: 0px;
                width: 170px;
            }
        }
        
        h5{
            font-size: 18px;
            font-weight: bold;
            letter-spacing: .05em;
            margin-bottom: 30px;
            
            @include media_query(){
                font-size: 17px;
                margin-bottom: 15px;
            }
        }
        
        p{
            text-align: justify;
            font-size: 16px;
            letter-spacing: .01em;
            line-height: 1.8;
        }
    }
}






.faq_dl{
    padding-bottom: 160px;
    
    @include media_query(){
        padding: 0 25px 0;
    }
    
    .faq_item{
        // padding: 25px 0 60px 0;
        border-top: solid 1px #ccc;
        
        @include media_query(){
            // padding: 25px 0;
        }
        
        &:last-child{
            border-bottom: solid 1px #ccc;
        }
        
        dt{
            position: relative;
            cursor: pointer;
            padding: 25px 0;
            
            &:hover{
                opacity: .7;
            }
            
            @include media_query(){
                padding: 25px 60px 25px 0;
            }
            
            &::before,
            &::after{
                content: "";
                display: block;
                width: 14px;
                height: 2px;
                background-color: #e20021;
                position: absolute;
                right: 20px;
                top: 50%;
                translate: 50% 0;
            }
            
            &::after{
                rotate: 90deg;
                
                transition: rotate .25s;
            }
            
            &.open{
                &::after{
                    rotate: 0deg;
                }
            }
            
            span{
                display: grid;
                grid-template-columns: 16px 1fr;
                gap: 12px;
                align-items: center;
                line-height: 1.8;
                
                @include media_query(){
                    align-items: flex-start;
                }
                
                &::before{
                    content: "";
                    display: block;
                    width: 16px;
                    height: 19px;
                    background-image: url(../svg/icon_q.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    
                    @include media_query(){
                        margin-top: 5px;
                    }
                }
            }
            
            // & + dd{
            //     margin-top: 50px;
                
            //     @include media_query(){
            //         margin-top: 25px;
            //     }
            // }
        }
        
        dd{
            display: none;
            font-size: 16px;
            line-height: 1.8;
            padding: 25px 0 60px;
            
            @include media_query(){
                padding: 30px 0 60px;
            }
        }
    }
}


.banner_entry{
    &:hover{
        .banner_entry_wrap{
            background-color: rgba(#000, 0);
        }
    }
    
    .banner_entry_wrap{
        background-color: rgba(#000, .2);
        padding: 110px 0 65px 0;
        
        transition: background-color .25s;
        
        @include media_query(){
            padding: 85px 50px 70px;
        }
    }
    
    display: block;
    width: 100%;
    background-image: url(../img/recruit/banner_entry.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
    .banner_entry_inner{
        color: #fff;
        text-align: center;
        
        h3{
            font-family: 'Roboto', sans-serif;
            font-size: 36px;
            line-height: 2;
            
            @include media_query(){
                line-height: 1.6;
            }
            
            span{
                display: block;
                font-size: 14px;
            }
        }
        
        p{
            margin-top: 40px;
            font-size: 16px;
            line-height: 1.7;
            
            @include media_query(){
                margin-top: 35px;
            }
        }
        
        img{
            margin-top: 35px;
            max-width: 74px;
            height: auto;
        }
    }
}





.entry_inner{
    max-width: 880px;
    margin: 120px auto 0;
    
    @include media_query(){
        margin: 60px 0 0 0;
    }
}









.entry_inner{
    dl{
        display: grid;
        grid-template-columns: 1fr 630px;
        gap: 35px;
        
        @include media_query(){
            grid-template-columns: 1fr;
            gap: 10px;
            padding: 0 25px;
        }
        
        dt{
            display: flex;
            font-size: 16px;
            padding-top: 18px;
            font-weight: bold;
            
            span{
                display: grid;
                place-items: center;
                color: #fff;
                width: 42px;
                height: 19px;
                font-size: 12px;
                margin-left: auto;
                background-color: #999;
                border-radius: 3px;
                font-weight: normal;
                
                &.required{
                    background-color: #e20021;
                }
            }
        }
        
        dd{
            input[type=text],
            input[type=tel],
            input[type=email],
            select,
            textarea{
                display: block;
                width: 100%;
                border: solid 1px #b2b2b2;
                border-radius: 6px;
                padding: 15px;
                font-size: 15px;
            }
            
            ::placeholder{
                color: #b2b2b2;
            }
            
            &.has_radio{
                padding: 15px;
                
                @include media_query(){
                    padding: 0;
                    
                    .wpcf7-list-item.first{
                        margin-left: 0;
                    }
                }
            }
            
            p{
                margin-bottom: 30px;
            }
        }
    }
    
    .pp{
        text-align: center;
        margin-top: 60px;
        
        p{
            margin-bottom: 30px;
        }
    }
    
    .confirm{
        dl{
            dd{
                p{
                    margin: 0;
                    padding: 15px;
                }
            }
        }
    }
}

.btn_submit{
    cursor: pointer;
    display: grid;
    place-items: center;
    width: 320px;
    height: 72px;
    margin: 120px auto 0;
    background-color: #000;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    letter-spacing: .06em;
    text-align: center;
    
    background-image: url(../svg/icon_arrow.svg);
    background-repeat: no-repeat;
    background-position: right 30px center;
    background-size: 15px 3px;
    
    transition: opacity .25s;
    
    &:hover{
        opacity: .7;
    }
}
