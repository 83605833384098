@use "../base/vars" as *;

.contact{
    position: relative;
    z-index: 2;
    
    .contact_inner{
        .page_head{
            .page_introduction{
                // padding: 80px 20px 0;
                
                @include media_query(){
                    text-align: left;
                    padding: 0 25px;
                }
            }
        }
    }
    
    .contact_contents{
        background-color: #fff;
        
        .contact_contents_inner{
            max-width: 1040px;
            margin: 0 auto;
            padding-top: 130px;
            
            @include media_query(){
                padding-top: 30px;
            }
            
            .entry_inner{
                max-width: 880px;
                margin: 0 auto 0;
                padding-bottom: 20px;
            }
        }
    }
}

.wpcf7-response-output{
    text-align: center;
}




.thanks{
    margin-top: 80px;
    
    @include rem(16);
    line-height: 1.8;
    letter-spacing: .01em;
    text-align: center;
    
    @include media_query(){
        text-align: left;
        padding: 0 25px;
    }
}
