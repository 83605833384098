@use "../base/vars" as *;

.news{
    position: relative;
    z-index: 2;
    
    .news_inner{
        .page_head{
            .page_introduction{
                // padding: 80px 20px 0;
            }
        }
    }
    
    .news_contents{
        margin-top: 120px;
        
        @include media_query(){
            margin-top: 80px;
        }
        
        .news_contents_inner{
            display: flex;
            max-width: 1080px;
            margin: 0 auto;
            padding: 0 20px;
            gap: 20px;
            
            @include media_query(){
                flex-direction: column;
            }
        }
    }
}







.news_contents_main{
    width: 100%;
    max-width: 750px;
    margin-left: auto;
}

.news_list_head{
    display: grid;
    grid-template-columns: 1fr 90px 120px;
    gap: 20px;
    
    @include media_query(){
        display: none;
    }
    
    p{
        font-weight: bold;
        border-bottom: solid 2px #000;
        text-align: center;
        padding-bottom: 15px;
    }
}

.news_list{
    .news_list_ul{
        border-top: solid 1px #ccc;
        
        li{
            padding: 25px 30px 25px 10px;
            border-bottom: solid 1px #ccc;
            display: flex;
            align-items: center;
            gap: 50px;
            letter-spacing: .02em;
            
            background-image: url(../svg/icon_arrow_bk.svg);
            background-repeat: no-repeat;
            background-size: 15px 3px;
            background-position: right 10px center;
            
            @include media_query(){
                flex-wrap: wrap;
                gap: 10px 20px;
                padding: 16px 30px 16px 0;
                background-size: 18px 10px;
            }
            
            time{
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                color: #999;
                font-size: 16px;
            }
            
            .cat{
                display: flex;
                align-items: flex-start;
                gap: 5px;
                
                a{
                    display: inline-block;
                    font-size: 13px;
                    padding: 5px 12px;
                    border-radius: 30px;
                    background-color: #ebf2f2;
                    
                    transition: opacity .4s;
                    
                    &:hover{
                        opacity: .7;
                    }
                }
            }
            
            .post_title{
                flex: 1;
                font-size: 16px;
                
                transition: opacity .4s;
                
                @include media_query(){
                    width: 100%;
                    flex: auto;
                }
                
                &:hover{
                    opacity: .7;
                }
            }
        }
    }
}















.single{
    padding-bottom: 40px;
    border-bottom: solid 1px #ccc;
    
    .single_head{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 35px 20px;
        
        time{
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #999;
            letter-spacing: .02em;
        }
        
        .cat{
            display: flex;
            align-items: flex-start;
            gap: 5px;
            
            a{
                display: inline-block;
                font-size: 13px;
                padding: 5px 12px;
                border-radius: 30px;
                background-color: #ebf2f2;
                
                transition: opacity .4s;
                
                &:hover{
                    opacity: .7;
                }
            }
        }
        
        .post_title{
            width: 100%;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: .03em;
            line-height: 1.4;
            color: #231815;
            
            @include media_query(){
                font-size: 24px;
            }
        }
    }
    
    .single_contents{
        margin-top: 80px;
        font-size: 16px;
        line-height: 1.8;
        
        @include media_query(){
            margin-top: 45px;
        }
        
        .single_contents_thumbnail{
            img{
                width: auto;
                max-width: 100%;
                height: auto;
                vertical-align: bottom;
            }
        }
        
        .single_main{
            margin-top: 80px;
            
            @include media_query(){
                margin-top: 45px;
            }
        }
        
    }
}

.pager{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-top: 40px;
    
    @include media_query(){
        margin-top: 30px;
    }
    
    a{
        transition: opacity .4s;
        
        &:hover{
            opacity: .7;
        }
    }
    
    .prev{
        a{
            display: flex;
            align-items: center;
            
            &:hover{
                opacity: .7;
            }
            
            &::before{
                content: "";
                display: block;
                width: 7px;
                height: 14px;
                background-image: url(../svg/arrow.svg);
                margin-right: 25px;
                
                @include media_query(){
                    width: 5px;
                    height: 11px;
                    margin-right: 10px;
                }
            }
        }
    }
    
    .next{
        display: flex;
        justify-content: flex-end;
        
        a{
            display: flex;
            align-items: center;
            
            &::after{
                content: "";
                display: block;
                width: 7px;
                height: 14px;
                background-image: url(../svg/arrow.svg);
                rotate: 180deg;
                margin-left: 25px;
                
                @include media_query(){
                    width: 5px;
                    height: 11px;
                    margin-left: 10px;
                }
            }
        }
    }
}
