@use "../base/vars" as *;

.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background-color: #fff;
    
    &.top_header{
        transition: top 1s;
        top: -100%;
    }
    
    &.show{
        top: 0;
    }
    
    .header_inner{
        display: flex;
        align-items: center;
        padding: 0 30px;
        width: 100%;
        height: 80px;
        // max-width: 1200px;
        margin: 0 auto;
        
        @include media_query(){
            padding: 0 20px;
            height: 55px;
        }
        
        .logo{
            a{
                img{
                    width: 100%;
                    max-width: 198px;
                    height: auto;
                    
                    @include media_query(){
                        max-width: 170px;
                    }
                }
            }
        }
    }
}

.global{
    margin-left: auto;
    
    @include media_query(){
        display: none;
    }
    
    ul{
        display: flex;
        gap: 35px;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        letter-spacing: .04em;
        
        li{
            a{
                padding: 5px 0;
                border-bottom: solid 1px transparent;
                transition: border-color .4s;
                
                &[href]:hover{
                    border-color: #000;
                }
            }
            
            .disabled{
                color: #ccc;
            }
        }
    }
}

.navopen{
    display: block;
    width: 100%;
    max-width: 40px;
    height: 18px;
    margin-left: 50px;
    cursor: pointer;
    transition: opacity .4s;
    
    &:hover{
        opacity: .7;
    }
    
    img{
        width: 100%;
        max-width: 40px;
        height: auto;
    }
    
    @include media_query(){
        margin-left: auto;
    }
}






.hamburger{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: #000;
    
    visibility: hidden;
    opacity: 0;
    
    transition: visibility .4s, opacity .4s;
    
    // overflow: scroll;
    
    &.open{
        visibility: visible;
        opacity: 1;
    }
    
    .nav_inner{
        @include media_query(){
            overflow: scroll;
        }
        
        .nav_head{
            display: grid;
            place-items: center;
            padding: 40px 0;
            // max-width: 1200px;
            margin: 0 auto;
            
            @include media_query(){
                position: fixed;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 25px;
                
                a img{
                    width: 100%;
                    max-width: 170px;
                    height: auto;
                }
            }
            
            .navclose{
                cursor: pointer;
                position: absolute;
                right: 30px;
                top: 30px;
                
                @include media_query(){
                    position: static;
                    margin-left: auto;
                }
                
                img{
                    width: 100%;
                    max-width: 40px;
                    height: auto;
                }
            }
        }
    }
}





.nav_main{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 870px;
    margin: 85px auto 0;
    color: #fff;
    
    @include media_query(){
        position: absolute;
        top: 118px;
        width: 100%;
        max-height: calc(100% - 118px);
        grid-template-columns: 1fr;
        padding: 0 50px 0;
        margin: 0 0 0;
        overflow: scroll;
    }
    
    .nav_main_list{
        > li{
            
            @include media_query(){
                border-top: solid 1px #4d4d4d;
                padding: 20px 0;
            }
            
            .icon_toggle{
                position: relative;
                display: none;
                place-items: center;
                width: 14px;
                height: 14px;
                margin: 0 15px 0 auto;
                
                @include media_query(){
                    display: grid;
                }
                
                &::before{
                    position: absolute;
                    content: "";
                    width: 14px;
                    height: 1px;
                    background-color: #e20021;
                }
                
                &::after{
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 14px;
                    background-color: #e20021;
                    
                    transition: rotate .4s;
                }
            }
            
            .open{
                .icon_toggle{
                    &::after{
                        rotate: 90deg;
                    }
                }
            }
            
            & + li{
                margin-top: 45px;
                
                @include media_query(){
                    margin-top: 0;
                }
            }
            
            > a{
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                
                @include media_query(){
                    font-size: 15px;
                }
                
                span{
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                    color: #666;
                    font-size: 14px;
                    margin-left: 20px;
                    
                    @include media_query(){
                        font-size: 11px;
                    }
                }
                
                &[href]{
                    transition: color .25s;
                    &:hover{
                        color: $main-color;
                    }
                }
                
                &.disabled{
                    color: #333;
                    
                    span{
                        color: #333;
                    }
                }
            }
            
            ul{
                margin-top: 30px;
                
                @include media_query(){
                    display: none;
                }
                
                li{
                    
                    & + li{
                        margin-top: 16px;
                    }
                    
                    a{
                        font-size: 14px;
                        
                        &::before{
                            content: "ー";
                            font-size: 14px;
                            color: #666;
                            margin-right: 12px;
                        }
                        
                        transition: color .25s;
                        &:hover{
                            color: $main-color;
                        }
                    }
                }
            }
        }
        
        .contact{
            @include media_query(){
                border-bottom: solid 1px #4d4d4d;
            }
        }
        
        .instagram{
            @include media_query(){
                border: none;
            }
            
            a{
                display: flex;
                align-items: center;
                gap: 17px;
                
                @include media_query(){
                    gap: 13px;
                    font-size: 14px;
                }
            }
            
            img{
                width: 100%;
                max-width: 24px;
                height: auto;
                
                @include media_query(){
                    max-width: 20px;
                }
            }
        }
        
        .wald{
            @include media_query(){
                border: none;
                margin-top: 40px;
            }
            
            a[href]{
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
            }
            
            img{
                @include media_query(){
                    max-width: 97px;
                }
            }
        }
    }
}









.main{
}










.sidebar{
    @include media_query(){
        order: 1;
        margin-top: 80px;
    }
    .category{
        & + .category{
            margin-top: 70px;
        }
        
        .category_title{
            font-weight: bold;
            font-size: 18px;
            letter-spacing: .02em;
            
            @include media_query(){
                display: flex;
                align-items: center;
            }
            
            span{
                font-family: 'Roboto', sans-serif;
                display: block;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.8;
                color: #b2b2b2;
                
                @include media_query(){
                    margin-left: auto;
                }
            }
        }
        
        .category_inner{
            margin-top: 25px;
            
            ul{
                @include media_query(){
                    border-bottom: solid 1px #ccc;
                }
                
                li{
                    @include media_query(){
                        border-top: solid 1px #ccc;
                    }
                    
                    a{
                        font-size: 15px;
                        letter-spacing: .03em;
                        line-height: 2;
                        
                        @include media_query(){
                            display: flex;
                            align-items: center;
                            padding: 8px 14px;
                            
                            &::after{
                                content: "";
                                display: block;
                                width: 18px;
                                height: 10px;
                                background-image: url(../svg/icon_arrow_bk.svg);
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: contain;
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}







.index_main,
.recruit_main,
.about_main{
    & + .footer{
        padding: 0;
        
        @include media_query(){
            padding: 0;
        }
        
        .footer_inner{
            border: none;
        }
    }
}

.footer{
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding-top: 170px;
    
    @include media_query(){
        padding-top: 70px;
    }
    
    .footer_inner{
        border-top: solid 1px #ccc;
        padding: 90px 0 0;
        
        @include media_query(){
            padding: 70px 0 0;
        }
    }
}

.footer_contact{
    max-width: 1240px;
    margin: 0 auto;
    display: grid;
    padding: 0 80px;
    grid-template-columns: 33.3333% 1fr;
    
    @include media_query(){
        grid-template-columns: 1fr;
        padding: 0 25px;
        gap: 40px;
    }
    
    .footer_contact_head{
        h2{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 32px;
            
            span{
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: bold;
                display: block;
                font-size: 14px;
            }
        }
        
        p{
            margin-top: 55px;
            font-size: 16px;
            line-height: 1.8;
        }
    }
    
    .footer_contact_main{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        
        @include media_query(){
            grid-template-columns: 1fr;
            padding: 0 7px;
        }
        
        .means_tel{
            h3{
                text-align: center;
                
                img{
                    width: 27px;
                    height: 46px;
                }
                
                span{
                    margin-top: 15px;
                    display: block;
                    font-weight: bold;
                    font-size: 16px;
                    
                    @include media_query(){
                        margin-top: 5px;
                    }
                }
            }
            
            .tel{
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-size: 21px;
                margin-top: 35px;
                
                @include media_query(){
                    margin-top: 8px;
                }
                
                span{
                    font-size: 32px;
                }
            }
            
            p{
                text-align: center;
                font-size: 13px;
            }
        }
        
        .means_mail{
            border-left: solid 1px #ccc;
            padding-top: 20px;
            
            @include media_query(){
                margin-top: 40px;
                border-left: none;
                border-top: solid 1px #ccc;
                padding-top: 40px;
            }
            
            h3{
                text-align: center;
                
                img{
                    width: 42px;
                    height: 28px;
                }
                
                span{
                    margin-top: 15px;
                    display: block;
                    font-weight: bold;
                    
                    @include media_query(){
                        margin-top: 5px;
                    }
                }
            }
            
            .btn_mail{
                margin-top: 30px;
                
                @include media_query(){
                    margin-top: 10px;
                }
                
                a{
                    position: relative;
                    display: grid;
                    place-items: center;
                    background-color: #000;
                    color: #fff;
                    font-size: 16px;
                    width: 100%;
                    max-width: 300px;
                    height: 72px;
                    margin: 0 auto;
                    border-radius: 50px;
                    
                    transition: opacity .4s;
                    
                    @include media_query(){
                        height: 64px;
                        font-size: 15px;
                    }
                    
                    &.disabled{
                        opacity: .5;
                    }
                    
                    &::after{
                        position: absolute;
                        top: 50%;
                        right: 25px;
                        transform: translateY(-50%);
                        content: "";
                        display: block;
                        width: 20px;
                        height: 10px;
                        background-image: url(../svg/icon_arrow.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }
                    
                    &[href]:hover{
                        opacity: .7;
                    }
                }
            }
        }
    }
}






.footer_contents{
    margin-top: 100px;
    background-color: #000;
    padding-bottom: 50px;
    
    @include media_query(){
        padding: 0 25px 20px;
    }
    
    .footer_contents_inner{
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 15%;
        max-width: 1240px;
        margin: 0 auto;
        padding: 85px 80px 90px;
        
        @include media_query(){
            grid-template-columns: 1fr;
            padding: 70px 0 0;
            gap: 0;
        }
    }
    
    .footer_logo{
        margin-bottom: 50px;
        
        @include media_query(){
            margin-bottom: 25px;
        }
        
        img{
            width: 100%;
            max-width: 241px;
            height: auto;
            
            @include media_query(){
                max-width: 170px;
            }
        }
    }
}

.footer_contents_info_item{
    color: #fff;
    
    & + .footer_contents_info_item{
        margin-top: 30px;
    }
    
    h3{
        font-size: 15px;
        margin-bottom: 10px;
        
        &::before{
            content: "ー";
            color: $main-color;
            margin-right: .8em;
        }
    }
    
    p{
        font-size: 14px;
        line-height: 1.8;
    }
}

.footer_nav_pc{
    display: grid;
    
    @include media_query(){
        display: none;
    }
}

.footer_nav{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: #fff;
    
    @include media_query(){
        grid-template-columns: repeat(2, 1fr);
    }
    
    .nav_main_list_wrap{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    
    .nav_main_list{
        
        
        > li{
            
            // @include media_query(){
            //     border-top: solid 1px #4d4d4d;
            //     padding: 20px 0;
            // }
            
            & + li{
                margin-top: 30px;
                
                // @include media_query(){
                //     margin-top: 0;
                // }
            }
            
            > a{
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                display: flex;
                align-items: center;
                font-size: 16px;
                
                @include media_query(){
                    font-size: 15px;
                }
            }
            
            ul{
                margin-top: 20px;
                
                li{
                    
                    & + li{
                        margin-top: 8px;
                    }
                    
                    a{
                        font-weight: 400;
                        font-size: 14px;
                        
                        &::before{
                            content: "ー";
                            font-size: 14px;
                            color: #666;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
        
        .contact{
            // @include media_query(){
            //     border-bottom: solid 1px #4d4d4d;
            // }
            
            .disabled{
                opacity: .5;
            }
        }
        
        .instagram{
            @include media_query(){
                border: none;
            }
            
            a{
                display: flex;
                align-items: center;
                gap: 17px;
            }
            
            img{
                width: 100%;
                max-width: 24px;
                height: auto;
            }
        }
        
        .wald{
            @include media_query(){
                border: none;
            }
        }
    }
    
    .nav_main_list_small{
        @include media_query(){
            margin-top: 30px;
        }
        
        li{
            & + li{
                margin-top: 8px;
            }
            
            a{
                font-size: 14px;
            }
        }
    }
    
    .nav_main_list_wald{
        margin-top: 30px;
        border-top: solid 1px #4d4d4d;
        padding-top: 30px;
        
        .wald{
            a{
                @include media_query(){
                    display: flex;
                    align-items: center;
                }
                
                p{
                    font-size: 13px;
                    
                    &::before{
                        content: "ー";
                        color: #006647;
                        margin-right: 12px;
                    }
                }
                
                img{
                    margin-top: 8px;
                    padding-left: 1.5em;
                    
                    @include media_query(){
                        margin: 0;
                    }
                }
            }
        }
    }
    
    .nav_main_list_instagram{
        margin-top: auto;
        padding-bottom: 10px;
        
        .instagram a{
            display: flex;
            align-items: center;
            gap: 1em;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            
            @include media_query(){
                font-size: 12px;
            }
        }
    }
}

.footer_nav_sp{
    display: none;
    
    @include media_query(){
        display: grid;
        gap: 20px;
        margin-bottom: 60px;
    }
}
.footer_nav_bottom{
    display: none;
    
    @include media_query(){
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 40px;
    }
}



.copyright{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #808080;
    
    @include media_query(){
        margin-top: 45px;
    }
}








.pagenation{
    font-family: 'Roboto', sans-serif;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    
    .prev{
        font-size: 13px;
        
        a{
            display: flex;
            align-items: center;
            
            &:hover{
                border-bottom-color: transparent;
            }
            
            &::before{
                content: "";
                display: block;
                width: 7px;
                height: 14px;
                background-image: url(../svg/arrow.svg);
                margin-right: 25px;
                
                @include media_query(){
                    width: 5px;
                    height: 11px;
                    margin-right: 10px;
                }
            }
        }
        
    }
    
    .next{
        font-size: 13px;
        
        a{
            display: flex;
            align-items: center;
            
            &:hover{
                border-bottom-color: transparent;
            }
            
            &::after{
                content: "";
                display: block;
                width: 7px;
                height: 14px;
                background-image: url(../svg/arrow.svg);
                rotate: 180deg;
                margin-left: 25px;
                
                @include media_query(){
                    width: 5px;
                    height: 11px;
                    margin-left: 10px;
                }
            }
        }
        
    }
    
    li{
        a,span{
            font-size: 16px;
            padding: 0 1em 1em;
            border-bottom: solid 1px transparent;
            
            @include media_query(){
                padding: 0 14px 14px;
            }
        }
        
        a{
            &:hover{
                border-bottom-color: #000;
            }
        }
        
        &.active{
            span{
                border-bottom-color: #000;
            }
        }
    }
}



.nav-links{
    font-family: 'Roboto', sans-serif;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    
    a{
        font-size: 16px;
        padding: 0 1em 1em;
        border-bottom: solid 1px transparent;
        
        @include media_query(){
            font-size: 14px;
            padding: 0 10px 14px;
        }
        
        &:hover{
            border-bottom-color: #000;
        }
        
        &.prev{
            &:hover{
                border-bottom-color: transparent;
                opacity: .5;
            }
            
            i{
                display: flex;
                align-items: center;
                
                &::before{
                    content: "";
                    display: block;
                    width: 7px;
                    height: 14px;
                    background-image: url(../svg/arrow.svg);
                    margin-right: 25px;
                    
                    @include media_query(){
                        width: 5px;
                        height: 11px;
                        margin-right: 5px;
                    }
                }
            }
        }
        
        &.next{
            &:hover{
                border-bottom-color: transparent;
                opacity: .5;
            }
            
            i{
                display: flex;
                align-items: center;
                
                &::after{
                    content: "";
                    display: block;
                    width: 7px;
                    height: 14px;
                    background-image: url(../svg/arrow.svg);
                    rotate: 180deg;
                    margin-left: 25px;
                    
                    @include media_query(){
                        width: 5px;
                        height: 11px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    
    span{
        font-size: 16px;
        padding: 0 1em 1em;
        border-bottom: solid 1px transparent;
        
        @include media_query(){
            padding: 0 10px 14px;
        }
    }
    
    .current{
        border-bottom-color: #000;
    }
}
