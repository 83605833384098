@use "../base/vars" as *;

.index{
    
}



.opening{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    height: 100svh;
    background-color: rgba(#000, 1);
    
    display: flex;
    
    /* これを追加 */
    overscroll-behavior: contain;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    touch-action: none;
    
    animation: opening 5s ease 0s forwards;
    
    // &::before,
    &::after {
        content: "";
        width: 1px;
        height: calc(100vh + 1px);
        display: flex;
        // background-color: #f00;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    
    // .test{
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100vh;
    //     overflow: hidden;
    // }
    
    .opening_inner{
        position: absolute;
        top: 0;
        left: 0;
        display: grid;
        place-content: center;
        width: 100%;
        height: 100vh;
        height: 100svh;
        overflow: hidden;
        
        img{
            max-width: 175px;
            height: auto;
            
            animation: logo 3s ease 0s forwards;
        }
        
        .curtain{
            position: absolute;
            top: 200%;
            left: -10%;
            z-index: 2;
            width: 180vw;
            height: 90%;
            background-color: #e20221;
            rotate: -5deg;
            
            animation: curtain 4s ease 0s forwards;
        }
    }
}


@keyframes logo{
    0% {
        scale: 1.2;
        opacity: 0;
        margin-top: 100px;
    }

    40% {
        scale: 1;
        opacity: 1;
        margin-top: 0;
    }
    
    80% {
        scale: 1;
        opacity: 1;
        margin-top: 0;
    }
    
    100% {
        scale: 1;
        opacity: 0;
        margin-top: -100px;
    }
}

@keyframes curtain{
    0% {
        top: 130%;
    }

    55% {
        top: 130%;
    }
    
    80% {
        top: -100%;
    }
    
    100% {
        top: -100%;
    }
}

@keyframes opening{
    0% {
        opacity: 1;
        visibility: visible;
    }
    
    50% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}



.firstview{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
    
    .video{
        width: 100%;
        height: 100vh;
        
        video{
            width: 100%;
            height: 100vh;
            object-fit: cover;
            vertical-align: bottom;
        }
        
        .video_pc{
            @include media_query(){
                display: none;
            }
        }
        
        .video_sp{
            display: none;
            
            @include media_query(){
                display: block;
            }
        }
    }
    
    .firstview_layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        
        .firstview_layer_header{
            position: relative;
            // position: absolute;
            // top: 0;
            // left: 0;
            width: 100%;
            // max-width: 1200px;
            text-align: center;
            padding: 40px;
            margin: 0 auto;
            
            @include media_query(){
                padding: 25px 25px;
            }
            
            img{
                max-width: 175px;
                height: auto;
                
                @include media_query(){
                    max-width: 140px;
                }
            }
            
            .navopen{
                position: absolute;
                right: 30px;
                top: 30px;
                
                img{
                    width: 40px;
                }
            }
        }
        
        h2{
            width: 100%;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-style: italic;
            font-size: 64px;
            letter-spacing: 0.01em;
            color: #fff;
            text-align: center;
            
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            
            @include media_query(){
                width: 100%;
                font-size: 38px;
            }
            
            br{
                display: none;
                
                @include media_query(){
                    display: block;
                }
            }
        }
    }
    
    .about_layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 0;
        background-color: #000;
        opacity: 0;
        
        .about_layer_inner{
            display: grid;
            place-items: center;
            width: 100%;
            max-width: 990px;
            height: 100vh;
            margin: 0 auto;
            
            @include media_query(){
                padding: 20px 0 0 0;
            }
            
            .about_layer_main{
                display: grid;
                grid-template-columns: 39.7777% 1fr;
                width: 100%;
                color: #fff;
                opacity: 0;
                padding: 85px 0 0;
                
                background-image: url(../img/index/about_bg.webp);
                background-position: left top -60px;
                background-size: 500px 500px;
                background-repeat: no-repeat;
                
                @include media_query(){
                    display: block;
                    padding: 0 25px;
                    background-size: 333px 333px;
                    background-position: right -45px top -50px;
                }
            }
            
            h2{
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                font-size: 36px;
                
                @include media_query(){
                    font-size: 32px;
                }
            }
            
            h3{
                font-size: 34px;
                line-height: 1.6;
                
                @include media_query(){
                    margin: 60px 0 0 0;
                    font-size: 26px;
                }
            }
            
            p{
                margin-top: 65px;
                font-size: 19px;
                line-height: 2;
                
                @include media_query(){
                    margin-top: 30px;
                    font-size: 15px;
                }
            }
            
            .btn_more{
                a{
                    border-bottom-color: #fff;
                    
                    &:hover{
                        border-bottom-color: transparent;
                    }
                }
            }
        }
    }
}




.indexSwiper{
    position: relative;
    background-color: #000;
    padding: 100px 0;
    max-height: 100vh;
    overflow: hidden;
    
    @include media_query(){
        padding: 30px 0 100px;
    }
    
    .swiper-wrapper{
        transition-timing-function:linear;
    }
    
    .index_swiper_item{
        img{
            vertical-align: bottom;
            width: 100%;
            height: auto;
        }
    }
}






.indexBuisiness{
    background-image: url(../img/index/buisiness.webp);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    
    @include media_query(){
        background-image: url(../img/index/buisiness_sp.webp);
    }
    
    .indexBuisiness_inner{
        // max-width: 990px;
        max-height: 100vh;
        padding: 15% 6.6666%;
        margin: 0 auto;
        
        @include media_query(){
            padding: 80px 25px 280px;
        }
        
        h2{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 36px;
            
            span{
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: bold;
                margin-top: 10px;
                display: block;
                font-size: 14px;
                letter-spacing: .04em;
            }
        }
        
        p{
            margin-top: 60px;
            font-size: 16px;
            line-height: 2;
            letter-spacing: .02em;
        }
    }
}





.btn_more{
    margin-top: 50px;
    
    a{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 2;
        border-bottom: solid 1px #000;
        padding-bottom: .4em;
        transition: border-bottom-color .25s;
        
        &:hover{
            border-bottom-color: transparent;
        }
    }
}







.indexNews{
    .indexNews_inner{
        max-width: 990px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 220px 1fr;
        gap: 20px;
        padding: 100px 0;
        
        @include media_query(){
            grid-template-columns: 1fr;
            padding: 80px 25px 0;
        }
    }
    
    .indexNews_title{
        display: flex;
        flex-direction: column;
        padding: 0 0 30px 0;
        
        h2{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 36px;
            margin-bottom: auto;
            
            span{
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: bold;
                margin-top: 10px;
                display: block;
                font-size: 14px;
                letter-spacing: .04em;
            }
        }
        
        .btn_more{
            @include media_query(){
                display: none;
            }
        }
    }
    
    .sp_btn_more{
        display: none;
        text-align: right;
        
        @include media_query(){
            display: block;
            padding: 0 50px 80px;
        }
    }
}

.indexLink{
    // max-width: 1200px;
    // margin: 0 auto;
    
    ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        
        @include media_query(){
            grid-template-columns: 1fr;
        }
    }
    
    li{
        
        &.indexLink_works a::before{
            background-image: url(../img/index/works.webp)
        }
        
        &.indexLink_recruit a::before{
            background-image: url(../img/index/recruit.webp)
        }
        
        a{
            position: relative;
            aspect-ratio: 1 / 1;
            display: grid;
            place-content: center;
            text-align: center;
            transition: background-color .25s;
            
            @include media_query(){
                aspect-ratio: 39 / 45;
            }
            
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
            
            &:hover{
                background-color: rgba(#000, .5);
            }
        }
    }
    
    
    h2{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #fff;
        font-size: 36px;
        
        span{
            font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
            font-weight: bold;
            display: block;
            font-size: 14px;
        }
    }
    
    img{
        width: 75px;
        height: 75px;
        margin: 80px auto 0;
    }
}
