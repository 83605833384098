@use "../base/vars" as *;

.privacy{
    position: relative;
    z-index: 2;
    
    .privacy_inner{
        .page_head{
            .page_introduction{
                // padding: 80px 20px 0;
            }
        }
    }
    
    .privacy_contents{
        font-size: 16px;
        line-height: 1.8;
        background-color: #fff;
        
        .privacy_contents_inner{
            max-width: 1090px;
            margin: 0 auto;
            padding: 0 25px;
        }
    }
}

.privacy_contents_introduction{
    margin-top: 120px;
    
    @include media_query(){
        margin-top: 75px;
    }
}

.privacy_main{
    margin-top: 100px;
    
    @include media_query(){
        margin-top: 70px;
    }
    
    & + .privacy_main{
        margin-top: 60px;
    }
    
    h3{
        font-size: 21px;
        font-weight: bold;
    }
    
    p{
        margin-top: 30px;
    }
    
    ul{
        margin-top: 30px;
        padding-left: 1.2em;
        
        li{
            list-style: disc;
        }
    }
}
