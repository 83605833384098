@charset "UTF-8";

//*------------------------------------*//
//	Base
//*------------------------------------*//

@use 'base/reset';
@use 'base/default';






//*------------------------------------*//
//	page
//*------------------------------------*//
@use "page/common";
@use "page/index";
@use "page/about";
@use "page/news";
@use "page/works";
@use "page/business";
@use "page/recruit";
@use "page/contact";
@use "page/privacy";
@use "page/sitemap";


@use "page/modal";




//*------------------------------------*//
//	fonts
//*------------------------------------*//
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,500&display=swap');
