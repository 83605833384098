@use "sass:math";

///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
$line-height: 1.5;




///*------------------------------------*//
//    Colors
//*------------------------------------*//
$main-color: #e20221;
$accent-color: #f00;
$font-color: #000;
$base-color: #fff;
$bg-color: #fff;




///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media_query($width:640) {
    @media screen and (max-width: $width+px) {
        @content;
    }
}

//フォントサイズ計算
@mixin rem($size, $base: 16) {
    font-size: math.div($size, $base) + rem;
}
