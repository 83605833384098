@use "../base/vars" as *;

.about{
    position: relative;
    z-index: 2;
}

.page_img{
    height: 400px;
    overflow: hidden;
    
    @include media_query(){
        height: 200px;
    }
    
    img{
        width: 100%;
        height: auto;
        margin-top: -20%;
    }
    
    & + .page_introduction{
        margin-top: 120px;
        
        @include media_query(){
            margin-top: 80px;
        }
    }
}



.about_contents{
    margin-top: 120px;
    
    @include media_query(){
        margin-top: 80px;
    }
}

.about_section{
    max-width: 1040px;
    margin: 0 auto;
    
    &.overview{
        max-width: 100%;
        background-color: #f2f6f6;
        padding: 120px 0 160px 0;
        margin-top: 160px;
        
        @include media_query(){
            margin-top: 80px;
            padding: 60px 0 80px 0;
        }
        
        .about_section_inner{
            max-width: 1040px;
            margin: 0 auto;
        }
    }
    
    &.access{
        padding: 120px 0 0 0;
        
        @include media_query(){
            padding: 75px 0 80px 0;
        }
    }
}

.about_section_inner{
    display: grid;
    grid-template-columns: 1fr 650px;
    gap: 20px;
    
    @include media_query(1040){
        grid-template-columns: 1fr;
        gap: 60px;
    }
    
    .section_title{
        @include media_query(1040){
            padding: 0 25px;
        }
        
        h3{
            font-size: 38px;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            letter-spacing: .01em;
            
            @include media_query(){
                font-size: 36px;
            }
            
            span{
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: 500;
                display: block;
                font-size: 12px;
                letter-spacing: .04em;
                // font-weight: normal;
            }
        }
    }
    
    .section_main{
    }
}





.about_message{
    @include media_query(){
        padding: 0 25px;
    }
    
    h4{
        font-size: 29px;
        font-weight: bold;
        line-height: 1.6;
        margin-bottom: 70px;
        
        @include media_query(){
            font-size: 21px;
            margin-bottom: 40px;
        }
    }
    
    p{
        font-size: 16px;
        line-height: 2;
        
        & + p{
            margin-top: 2em;
        }
    }
    
    .signature{
        margin-top: 60px;
        text-align: right;
    }
}




.access_item{
    
    & + .access_item{
        margin-top: 120px;
    }
    
    h4{
        display: flex;
        align-items: center;
        font-size: 21px;
        font-weight: bold;
        
        @include media_query(){
            padding: 0 25px;
        }
        
        &::before{
            content: "";
            width: 23px;
            height: 1px;
            background-color: #e20021;
            margin-right: 12px;
        }
    }
    
    .access_item_inner{
        margin-top: 25px;
        padding-left: 35px;
        
        @include media_query(){
            padding: 0 20px;
        }
        
        p{
            font-size: 15px;
            line-height: 1.8;
            
            a{
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
            }
        }
        
        & > p{
            @include media_query(){
                padding: 0 0 0 30px;
            }
        }
    }
}

.access_item_map{
    margin-top: 50px;
    
    @include media_query(){
        margin-top: 25px;
    }
    
    iframe{
        width: 100%;
        
        @include media_query(){
            height: 200px;
        }
    }
}

.access_item_img{
    margin-top: 30px;
    
    figure{
        margin: 0;
        
        & + figure{
            margin-top: 10px;
        }
        
        img{
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }
    }
}


.logomark{
    display: grid;
    grid-template-columns: 207px 1fr;
    align-items: center;
    padding-top: 85px;
    
    @include media_query(){
        grid-template-columns: 1fr;
        gap: 60px;
        padding: 0 25px;
    }
    
    .mark{
        @include media_query(){
            text-align: center;
        }
        
        img{
            width: 100%;
            height: auto;
            
            @include media_query(){
                text-align: center;
            }
            
            @include media_query(){
                max-width: 116px;
            }
        }
    }
    
    .text{
        margin-left: 120px;
        font-size: 15px;
        line-height: 1.8;
        
        @include media_query(){
            max-width: 304px;
            margin: 0 auto;
        }
    }
}
