@use "../base/vars" as *;

.business{
    position: relative;
    z-index: 2;
    
    .business_inner{
        .page_head{
            // .page_introduction{
            //     padding: 80px 20px 0;
            // }
        }
    }
    
    .business_contents{
        .business_contents_inner{
            max-width: 1040px;
            margin: 0 auto;
            padding-top: 130px;
            
            @include media_query(){
                padding-top: 80px;
            }
        }
    }
}


.business_item{
    display: flex;
    align-items: flex-end;
    gap: 70px;
    
    @include media_query(){
        flex-direction: column;
        gap: 50px;
    }
    
    & + .business_item{
        margin-top: 120px;
        
        @include media_query(){
            margin-top: 60px;
        }
    }
    
    &:nth-child(even){
        .business_item_contents{
            order: 1;
        }
        
        .business_item_img{
            .number{
                right: auto;
                left: 30px;
                
                @include media_query(){
                    right: 25px;
                    left: auto;
                }
            }
        }
    }
    
    .business_item_contents{
        flex: 1;
        // padding-top: 130px;
        
        @include media_query(){
            order: 1;
            padding: 0 25px;
        }
        
        h3{
            font-size: 28px;
            font-weight: bold;
            letter-spacing: .04em;
            
            @include media_query(){
                font-size: 24px;
            }
        
            & + p{
                margin-top: 30px;
                font-size: 16px;
                line-height: 1.8;
            }
        }
        
        .example{
            margin-top: 20px;
            background-color: #f2f6f6;
            padding: 20px;
            
            p{
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
    
    .business_item_img{
        width: 100%;
        max-width: 560px;
        position: relative;
        padding-top: 100px;
        margin: 0;
        
        @include media_query(){
            padding-top: 70px;
        }
        
        img{
            width: 100%;
            height: auto;
        }
        
        .number{
            position: absolute;
            right: 30px;
            top: 0;
            width: 100%;
            max-width: 196px;
            height: auto;
            
            @include media_query(){
                max-width: 140px;
            }
        }
        
        .business_img{
            vertical-align: bottom;
        }
    }
}
