@use 'vars' as *;

html{
    scroll-behavior: smooth;
    scroll-padding-top: 80px;
    
    @include media_query(){
        scroll-padding-top: 55px;
    }
}

body{
    font-family: $font-family;
    color: $font-color;
    background-color: $bg-color;
}
