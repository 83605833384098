@use "../base/vars" as *;

.sitemap{
    position: relative;
    z-index: 2;
    
    .sitemap_inner{
        .page_head{
            .page_introduction{
                // padding: 80px 20px 0;
            }
        }
    }
    
    .sitemap_contents{
        font-size: 16px;
        line-height: 1.8;
        background-color: #fff;
        
        .sitemap_contents_inner{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            max-width: 1090px;
            margin: 0 auto;
            padding: 0 25px;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
        }
    }
}

.sitemap_main{
    
    .sitemap_item{
        margin-top: 60px;
    }
    
    h3{
        a{
            font-size: 36px;
            font-weight: bold;
            
            &[href]{
                transition: color .25s;
                
                &:hover{
                    color: $main-color;
                }
            }
            
            span{
                display: block;
                font-size: 14px;
            }
            
            &.disabled{
                color: #ccc;
            }
        }
    }
    
    ul{
        margin-top: 30px;
        
        li{
            a{
                font-size: 16px;
                line-height: 2;
                transition: color .25s;
                
                &:hover{
                    color: $main-color;
                }
                
                &::before{
                    content: "ー";
                    margin-right: 10px;
                }
            }
        }
    }
}
